import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Gleap from "gleap";
import keycloak from "./keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);

// Please make sure to call this method only once!
if (process.env.NODE_ENV === "production") {
  Gleap.initialize("WmAKBdyROX2lepE1HSbrvUvUE1nsGwpO");
}
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const eventLogger = (event: unknown, error: unknown) => {
  console.log("onKeycloakEvent", event, error);
};

const tokenLogger = (tokens: any) => {
  localStorage.setItem(":crypto_token", tokens.token);
  localStorage.setItem(":crypto_refresh", tokens.refreshToken);
};

root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    onEvent={eventLogger}
    onTokens={tokenLogger}
    initOptions={{
      onLoad: "check-sso",
      checkLoginIframe: false,
    }}
  >
    <App />
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
