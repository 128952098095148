import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

function linkClassName(args: { isActive: boolean }): string {
  let defaultClass = 'hover:text-primary p-2';
  if (args.isActive) {
    defaultClass += ' text-primary';
  }
  return defaultClass;
}

const ConfigurationNavBar = () => {
  const { project } = useParams();
  const [isKYCFlowOpen, setIsKYCFlowOpen] = useState(false);
  const navigate = useNavigate();

  if (!project) {
    return <></>;
  }

  const baseUrl = `/projects/${project}/configuration`;

  return (
    <div className='p-4 flex flex-col w-72 shadow-inner h-full bg-gray-100 overflow-hidden'>
      <div className='text-xl p-2 pb-4 font-bold'>Configuration</div>
      <NavLink to={baseUrl} end className={linkClassName}>
        General
      </NavLink>
      <NavLink to={`${baseUrl}/customization`} end className={linkClassName}>
        Customization
      </NavLink>
      <NavLink
        to={`${baseUrl}/country-restrictions`}
        end
        className={linkClassName}
      >
        Country Restriction
      </NavLink>
      <NavLink to={`${baseUrl}/statistics`} end className={linkClassName}>
        Statistics
      </NavLink>
      <NavLink to={`${baseUrl}/kyc-flow`} end className={linkClassName}>
        KYC-Flow
      </NavLink>

      <div>
        <div
          className='flex items-center cursor-pointer'
          onClick={() => {
            setIsKYCFlowOpen(!isKYCFlowOpen);
            if (!isKYCFlowOpen) {
              navigate(`${baseUrl}/kyb-flow/`);
            }
          }}
        >
          <div className={linkClassName({ isActive: false })}>KYB-Flow</div>
          {!isKYCFlowOpen && (
            <ChevronRightIcon
              width={18}
              height={18}
              className='text-black ml-3'
            />
          )}
          {isKYCFlowOpen && (
            <ChevronDownIcon
              width={24}
              height={18}
              className='text-black ml-3'
            />
          )}
        </div>
        {isKYCFlowOpen && (
          <div className='flex flex-col ml-2'>
            <NavLink to={`${baseUrl}/kyb-flow`} className={linkClassName}>
              Base-Flow
            </NavLink>
            <NavLink
              to={`${baseUrl}/kyb-director-flow/`}
              className={linkClassName}
            >
              Director-Flow
            </NavLink>
            <NavLink
              to={`${baseUrl}/kyb-company-flow/`}
              className={linkClassName}
            >
              Company-Flow
            </NavLink>
            <NavLink to={`${baseUrl}/kyb-user-flow/`} className={linkClassName}>
              User-Flow
            </NavLink>
          </div>
        )}
      </div>
      <NavLink to={`${baseUrl}/team`} className={linkClassName}>
        Team
      </NavLink>
    </div>
  );
};

export default ConfigurationNavBar;
