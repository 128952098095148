import { action, makeObservable, observable } from 'mobx';
import axios from '../utils/Axios';
import { UserDto } from '../interfaces/User.dto';
import { VerificationStepType } from '../enums/VerificationStepType';
import CommentDto from '../interfaces/user/comment.dto';
import { VerificationStatus } from '../enums/VerificationStatus';
import UserVerificationServiceDto from '../interfaces/user/verification-service.dto';
import { isToApproveUser, isWarningUser } from '../utils/User.util';
import PaginationOptions from '../interfaces/pagination-options';
import { toast } from 'react-toastify';
import { verificationStepEntityName } from '../utils/VerificationSteps.util';
import { flowTypeSlug } from '../utils/FlowConfig.util';
import { approveUser, getUser, rejectUser } from '../api/user.api';

interface UserResponse {
  items: UserDto[];
  meta: PaginationOptions;
}

export class ClientUserStore {
  @observable user: UserDto | undefined;
  @observable isWarning: boolean = false;
  @observable isToApprove: boolean = false;
  @observable users: UserDto[] = [];
  @observable markedUsers: UserDto[] = [];
  @observable usersLoadingCount: number = 0;
  @observable userLoadingCount: number = 0;
  private usersMeta: PaginationOptions | undefined;
  private usersQuery: any | undefined;

  constructor() {
    makeObservable(this);
  }

  @action
  private setUsers(userResponse: UserResponse) {
    this.users = userResponse.items;
    this.usersMeta = userResponse.meta;
  }

  @action
  private setUser(user: UserDto) {
    this.user = user;
  }

  @action
  private addUsers(userResponse: UserResponse) {
    this.usersMeta = userResponse.meta;
    this.users = [...this.users, ...userResponse.items];
  }

  @action
  private setUsersQuery(query?: any) {
    this.usersQuery = query;
  }

  @action
  setIsWarning(isWarning: boolean) {
    this.isWarning = isWarning;
  }

  @action
  setIsToApprove(isToApprove: boolean) {
    this.isToApprove = isToApprove;
  }

  @action
  private addUserLoadingCount() {
    this.userLoadingCount += 1;
  }

  @action
  private removeUserLoadingCount() {
    this.userLoadingCount -= 1;
  }

  @action
  private addUsersLoadingCount() {
    this.usersLoadingCount += 1;
  }

  @action
  private removeUsersLoadingCount() {
    this.usersLoadingCount -= 1;
  }

  isUserEditable(user?: UserDto) {
    if (!user) {
      user = this.user;
    }

    return (
      user!.status !== VerificationStatus.REJECTED &&
      user!.status !== VerificationStatus.COMPLETED
    );
  }

  hasMoreUsers() {
    return this.usersMeta
      ? this.usersMeta.totalPages > this.usersMeta.currentPage
      : false;
  }

  private async getUsers(query?: any): Promise<UserResponse | undefined> {
    const response = await axios.get(
      `/client/users${this.getUrlParams(query)}`
    );
    let result: any = [];
    if (response.status === 200 && response.data) {
      for (const user of response.data.items) {
        // if (user.flowType === FlowType.KYC) {
        result.push(user);
        // }
      }
      response.data.items = result;

      return response.data;
    }
  }

  async getNextUsers() {
    let query = this.usersQuery;

    const page = this.usersMeta?.currentPage
      ? this.usersMeta?.currentPage + 1
      : 1;

    if (query) {
      query.page = page;
    } else {
      query = { page };
    }

    const res = await this.getUsers(query);
    if (res) {
      this.addUsers(res);
    } else {
      toast.error('Error getting Users');
    }
  }

  async initUsers(query?: any) {
    this.addUsersLoadingCount();

    if (this.isWarning) {
      if (query) {
        query.warning = true;
      } else {
        query = { warning: true };
      }
    } else if (this.isToApprove) {
      if (query) {
        query.toApprove = true;
      } else {
        query = { toApprove: true };
      }
    }

    const res = await this.getUsers(query);
    this.setUsersQuery(query);

    if (res) {
      this.setUsers(res);
    } else {
      toast.error('Error getting Users');
    }

    this.removeUsersLoadingCount();
  }

  @action
  resetUsers() {
    this.users = [];
    this.usersMeta = undefined;
    this.usersQuery = undefined;
  }

  @action
  addMarkedUser(user: UserDto) {
    if (!this.isMarkedUser(user)) {
      this.markedUsers.push(user);
    }
  }

  allUsersMarked() {
    if (this.users.length === this.markedUsers.length) {
      return this.users.every((u) =>
        this.markedUsers.some((um) => um.id === u.id)
      );
    }

    return false;
  }

  @action
  markAllUsers() {
    this.markedUsers = this.users;
  }

  @action
  removeAllMarkedUsers() {
    this.markedUsers = [];
  }

  @action
  removeMarkedUser(user: UserDto) {
    if (this.isMarkedUser(user)) {
      const index = this.markedUsers.findIndex((u) => u.id === user.id);
      this.markedUsers.splice(index, 1);
    }
  }

  isMarkedUser(user: UserDto) {
    return this.markedUsers.some((u) => u.id === user.id);
  }

  getUsersParentUrlParam() {
    if (this.isWarning) {
      return 'warning';
    }

    if (this.isToApprove) {
      return 'approve';
    }

    return 'users';
  }

  @action
  async getUser(args: {
    user: string;
    properties?: string[];
    loading?: boolean;
  }) {
    if (args.loading === undefined) {
      args.loading = true;
    }

    if (args.loading) {
      this.addUserLoadingCount();
    }

    let query = '';
    if (args.properties) {
      query += `?properties=${encodeURIComponent(args.properties as any)}`;
    }

    const response = await getUser({
      user: args.user,
      query,
    });

    if (args.loading) {
      this.removeUserLoadingCount();
    }
    if (response && response.status === 200) {
      this.setUser(response.data);
    }
  }

  @action
  async setComment(args: {
    stepType: VerificationStepType;
    comment: CommentDto;
  }) {
    if (this.user) {
      try {
        const response = await axios.post(
          `/client/users/${this.user!.id}/${args.stepType}/comment`,
          args.comment
        );

        if (response && response.data && response.data.length > 0) {
          this.setVerificationStepComments({
            verificationStepType: args.stepType,
            comments: response.data,
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  @action
  async setServiceStatus(args: {
    service: UserVerificationServiceDto;
    status: VerificationStatus;
    stepType: VerificationStepType;
  }) {
    args.service.status = args.status;
    if (this.user) {
      try {
        const response = await axios.post(
          `/client/users/${this.user.id}/${flowTypeSlug(this.user.flowType)}/${
            args.stepType
          }/${args.service.type}`,
          {
            status: args.status,
          }
        );

        if (response) {
          await this.getUser({
            user: this.user.id,
            loading: false,
            properties: [args.stepType],
          });
          this.updateUser();
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  @action
  private updateUser(user?: UserDto) {
    if (user) {
      this.setUser(user);
    } else {
      user = this.user;
    }

    if (user) {
      let index = this.users.findIndex((u) => u.id === user!.id);
      if (index >= 0) {
        if (
          (this.isWarning && !isWarningUser(user)) ||
          (this.isToApprove && !isToApproveUser(user))
        ) {
          this.initUsers(this.usersQuery);
        } else {
          this.users[index] = user;
        }
      }
    }
  }

  async setApproveUsers() {
    if (this.markedUsers.length > 0) {
      this.addUsersLoadingCount();

      for (const user of this.markedUsers) {
        await approveUser(user.id);
      }

      await this.initUsers(this.usersQuery);
      this.removeAllMarkedUsers();

      this.removeUsersLoadingCount();
    }
  }

  async setRejectUsers() {
    if (this.markedUsers.length > 0) {
      this.addUsersLoadingCount();

      for (const user of this.markedUsers) {
        await rejectUser(user.id);
      }
      this.initUsers(this.usersQuery);
      this.removeAllMarkedUsers();

      this.removeUsersLoadingCount();
    }
  }

  @action
  async setRedoVerificationStep(stepType: VerificationStepType) {
    try {
      this.addUserLoadingCount();

      const response = await axios.post(
        `/client/users/${this.user!.id}/${stepType}/redo`
      );

      if (response && response.data) {
        let user: any = { ...this.user };

        user[verificationStepEntityName(stepType)] = response.data;

        this.updateUser(user);
      }

      this.removeUserLoadingCount();
    } catch (err) {
      console.log(err);
    }
  }

  @action
  setVerificationStepComments(args: {
    verificationStepType: VerificationStepType;
    comments: CommentDto[];
  }) {
    const user: any = { ...this.user };
    if (user) {
      user[verificationStepEntityName(args.verificationStepType)].comments =
        args.comments;

      this.setUser(user);
    }
  }

  private getUrlParams(where: any) {
    if (where) {
      let urlParams = '';

      for (var item in where) {
        urlParams += urlParams ? '&' : '?';
        urlParams += `${item}=${encodeURIComponent(where[item])}`;
      }

      return urlParams;
    }
    return '';
  }

  @action
  resetUser() {
    this.user = undefined;
  }
}

const clientUserStore = new ClientUserStore();
export default clientUserStore;
