import { Menu, Transition } from '@headlessui/react';
import {
  ArrowUturnRightIcon,
  Cog8ToothIcon,
} from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { Role } from '../../../../enums/Role';
import { VerificationStepType } from '../../../../enums/VerificationStepType';
import clientUserStore from '../../../../stores/clientUser.store';
import RequireAuth from '../../../RequireAuth';

interface Props {
  step: VerificationStepType;
}

const VerificationStepSettings = ({ step }: Props) => {
  if (!clientUserStore.isUserEditable()) {
    return <></>;
  }

  return (
    <RequireAuth roles={[Role.ADMIN]}>
      <div className='h-full ml-2'>
        <Menu as='div' className='relative inline-block w-full h-full'>
          <Menu.Button className='flex items-center h-full data-[headlessui-state=open]:text-primary'>
            <Cog8ToothIcon className='h-5 w-5' />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='absolute right-0 mt-1 top-full origin-top-right z-10 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
              <div className='py-1'>
                <Menu.Item>
                  <button
                    onClick={() => {
                      clientUserStore.setRedoVerificationStep(step);
                    }}
                    className='flex w-full text-sm items-center px-4 py-1 bg-white hover:text-primary'
                  >
                    <ArrowUturnRightIcon
                      className='mr-3 h-4 w-4'
                      aria-hidden='true'
                    />
                    Redo
                  </button>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </RequireAuth>
  );
};

export default VerificationStepSettings;
