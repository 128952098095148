import { FlagIcon } from '@heroicons/react/24/outline';
import UserAddress from '../interfaces/address.dto';
import * as Flags from 'country-flag-icons/react/3x2';
import { countries } from '../data/countries';
import { countryDropDownOptions } from '../data/countries';

const getAddressString = (address?: UserAddress): string => {
  if (address) {
    if (address.city && address.zip && address.street) {
      return `${address?.street}, ${address?.zip} - ${address?.city}`;
    } else if (address.city && address.zip) {
      return `${address?.zip} - ${address?.city}`;
    } else if (address.city && address.street) {
      return `${address?.street}, ${address?.city}`;
    } else if (address.zip && address.street) {
      return `${address?.street}, ${address?.zip}`;
    } else if (address.city) {
      return address.city;
    } else if (address.street) {
      return address.street;
    } else if (address.zip) {
      return address.zip;
    }
  }
  return '';
};

const getCountryIcon = (country?: string): JSX.Element => {
  if (country) {
    const Flag = (Flags as any)[country];
    return <Flag />;
  }

  return <FlagIcon />;
};

const getCountryLabel = (country?: string): string => {
  if (country) {
    const countryObj = countries.find((c) => c.value === country);
    if (countryObj) {
      return countryObj.label;
    }
  }
  return '';
};

const getCountryLabelWithFlag = (country?: string): JSX.Element => {
  if (country) {
    const countryObj = countryDropDownOptions.find((c) => c.value === country);
    if (countryObj) {
      return countryObj.label;
    }
  }
  return <></>;
};

export {
  getAddressString,
  getCountryIcon,
  getCountryLabel,
  getCountryLabelWithFlag,
};
