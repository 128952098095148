import { Routes, Route, Navigate } from 'react-router-dom';
import NoAuth from '../../pages/NoAuth';
import Projects from '../../pages/Projects';
import CreateProject from '../../pages/CreateProject';
import Configuration from '../../pages/ProjectPages/Configuration';
import Profile from '../../pages/Profile';
import UserDetailLanding from '../../pages/ProjectPages/UserDetailPages/Landing';
import UserDetailOverview from '../../pages/ProjectPages/UserDetailPages/Overview';
import ProjectDetail from '../../pages/ProjectPages/Project';
import ConfigurationGeneral from '../../pages/ProjectPages/ConfigurationPages/General';
import Customization from '../../pages/ProjectPages/ConfigurationPages/Customization';
import Team from '../../pages/ProjectPages/ConfigurationPages/Team';
import Statistics from '../../pages/ProjectPages/ConfigurationPages/Statistics';
import {
  verificationStepSlug,
  verificationStepUserDetailPage,
} from '../../utils/VerificationSteps.util';
import CountryRestrictions from '../../pages/ProjectPages/ConfigurationPages/CountryRestrictions';
import Users from '../../pages/ProjectPages/Users/Users';
import { VerificationStepType } from '../../enums/VerificationStepType';
import Flow from '../../pages/ProjectPages/ConfigurationPages/Flow';
import FlowType from '../../enums/FlowType';

const Routing = () => {
  const renderUserDetailPages = (userBaseUrl: string) => {
    const userDetailBaseUrl = `${userBaseUrl}/:user`;

    return (
      <>
        <Route path={userBaseUrl} element={<UserDetailLanding />}></Route>
        <Route
          path={`${userBaseUrl}/:user`}
          element={<UserDetailOverview />}
        ></Route>
        {Object.values(VerificationStepType).map((stepType) => {
          return (
            <Route
              key={stepType}
              path={`${userDetailBaseUrl}/${verificationStepSlug(stepType)}`}
              element={verificationStepUserDetailPage(stepType)}
            ></Route>
          );
        })}
      </>
    );
  };

  const renderConfigurationPages = () => {
    const configurationBaseUrl = '/projects/:project/configuration';
    return (
      <>
        <Route
          path={configurationBaseUrl}
          element={<ConfigurationGeneral />}
        ></Route>
        <Route
          path={`${configurationBaseUrl}/statistics`}
          element={<Statistics />}
        ></Route>
        <Route
          path={`${configurationBaseUrl}/customization`}
          element={<Customization />}
        ></Route>
        <Route
          path={`${configurationBaseUrl}/country-restrictions`}
          element={<CountryRestrictions />}
        ></Route>
        <Route
          path={`${configurationBaseUrl}/kyc-flow`}
          element={<Flow flowType={FlowType.KYC} key={FlowType.KYC}/>}
        ></Route>

        <Route
          path={`${configurationBaseUrl}/kyb-flow`}
          element={<Flow flowType={FlowType.KYB} key={FlowType.KYB}/>}
        ></Route>

        <Route
          path={`${configurationBaseUrl}/kyb-director-flow`}
          element={<Flow flowType={FlowType.KYB_DIRECTOR} key={FlowType.KYB_DIRECTOR}/>}
        ></Route>

        <Route
          path={`${configurationBaseUrl}/kyb-company-flow`}
          element={<Flow flowType={FlowType.KYB_COMPANY} key={FlowType.KYB_COMPANY}/>}
        ></Route>

        <Route
          path={`${configurationBaseUrl}/kyb-user-flow`}
          element={<Flow flowType={FlowType.KYB_USER} key={FlowType.KYB_USER}/>}
        ></Route>
        <Route path={`${configurationBaseUrl}/team`} element={<Team />}></Route>
      </>
    );
  };

  return (
    <Routes>
      <Route path='/' element={<Navigate to='/projects' />} />
      <Route path='/index.html' element={<Navigate to='/projects' />} />
      <Route path='/projects' element={<Projects />}></Route>
      <Route path='/projects/create' element={<CreateProject />}></Route>
      <Route path='/profile' element={<Profile />}></Route>
      <Route path='/projects/:project' element={<ProjectDetail />}>
        <Route
          path='/projects/:project'
          element={<Navigate to='users' />}
        ></Route>
        <Route
          path='/projects/:project/configuration'
          element={<Configuration />}
        >
          {renderConfigurationPages()}
        </Route>
        <Route path='/projects/:project/users' element={<Users />}>
          {renderUserDetailPages('/projects/:project/users')}
        </Route>
        <Route path='/projects/:project/warning' element={<Users isWarning />}>
          {renderUserDetailPages('/projects/:project/warning')}
        </Route>
        <Route
          path='/projects/:project/approve'
          element={<Users isToApprove />}
        >
          {renderUserDetailPages('/projects/:project/approve')}
        </Route>
      </Route>
      <Route path={'/no-permission'} element={<NoAuth />}></Route>
    </Routes>
  );
};

export default Routing;
